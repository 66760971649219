:root {
  --sidebar-width: 260px;
  --transition-speed: 0.3s;
  --primary-color: #1ca8cb;
  --text-color: #333;
  --success-color: #28a745;
  --danger-color: #dc3545;
  --danger-hover: #bb2d3b;
  --secondary-color: #6c757d;
  --light-gray: #f2f2f2;
  --border-color: #ddd;
}

/* Overall page styling */
.jobcard-page {
  min-height: 100vh;
  background-color: #f8f9fa;
  color: var(--text-color);
}

/* Container for the sidebar and main content */
.jobcard-container {
  display: flex;
  position: relative;
}

/* Main content area: leave room for the sidebar on larger screens */
.main-content {
  flex: 1;
  margin-left: var(--sidebar-width);
  padding: 20px;
  transition: margin-left var(--transition-speed) ease;
}

/* On mobile, remove the left margin so content spans full width */
@media (max-width: 768px) {
  .main-content {
    margin-left: 0;
  }
  
  .date-filter-container {
    flex-direction: column;
  }
  
  .date-inputs {
    flex-direction: column;
  }
  
  .date-field {
    margin-bottom: 10px;
  }
}

/* Date filter controls */
.date-filter-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  margin-bottom: 20px;
  flex-wrap: wrap;
}

.date-inputs {
  display: flex;
  gap: 15px;
  align-items: center;
}

.date-field {
  display: flex;
  align-items: center;
}

.date-field label {
  margin-right: 8px;
  font-weight: 500;
}

.date-field input[type="date"] {
  padding: 8px;
  border: 1px solid var(--border-color);
  border-radius: 4px;
  font-family: inherit;
}

.date-buttons {
  display: flex;
  gap: 10px;
}

.btn-filter, .btn-clear {
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 500;
  transition: background-color 0.2s;
}

.btn-filter {
  background-color: var(--primary-color);
  color: white;
}

.btn-filter:hover {
  background-color: #178baa;
}

.btn-clear {
  background-color: var(--secondary-color);
  color: white;
}

.btn-clear:hover {
  background-color: #5a6268;
}

.filter-summary {
  margin-bottom: 20px;
  font-style: italic;
  color: var(--secondary-color);
}

/* Responsive grid container for job cards */
.grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-gap: 16px;
  margin-bottom: 2rem;
}

/* Styling for individual job card items */
.jobcard-card {
  border: 1px solid var(--border-color);
  border-radius: 8px;
  padding: 16px;
  background: #fff;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  cursor: pointer;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.jobcard-card:hover {
  transform: translateY(-4px);
  box-shadow: 0 4px 8px rgba(0,0,0,0.15);
}

.jobcard-card h3 {
  margin-top: 0;
  color: var(--primary-color);
}

/* Table styling for closed job cards */
.closed-section, .older-closed-section {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.closed-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 1rem;
  background-color: white;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.closed-table th, .closed-table td {
  border: 1px solid var(--border-color);
  padding: 12px 8px;
  text-align: left;
}

.closed-table th {
  background-color: var(--light-gray);
  position: sticky;
  top: 0;
}

.closed-table tr:hover {
  background-color: #f5f5f5;
}

/* Button styling */
.btn-view {
  background-color: var(--primary-color);
  color: white;
  border: none;
  padding: 6px 12px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.btn-view:hover {
  background-color: #178baa;
}

.btn-close {
  background-color: var(--danger-color);
  color: white;
  border: none;
  padding: 6px 12px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.btn-close:hover {
  background-color: var(--danger-hover);
}

/* Section headings */
.main-content h3 {
  margin-top: 2rem;
  color: var(--primary-color);
  border-bottom: 2px solid #eee;
  padding-bottom: 0.5rem;
}