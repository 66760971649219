/* Global Variables */
:root {
    --sidebar-width: 300px;
    --transition-speed: 0.3s;
    --primary-color: #28a745;
    --secondary-color: #6c757d;
    --danger-color: #dc3545;
    --warning-color: #ffc107;
    --info-color: #007bff;
    --background-color: #f8f9fa;
    --white: #fff;
    --font-family: Arial, sans-serif;
  }
  
  /* Dashboard Layout */
  .dashboard-container {
    display: flex;
    flex-direction: row;
    background-color: var(--background-color);
    min-height: 100vh;
  }
  
  /* Main Content Area */
  .main-content {
    margin-left: var(--sidebar-width);
    padding: 20px;
    flex: 1;
    background-color: var(--white);
    box-shadow: 0 0 10px rgba(0,0,0,0.1);
    border-radius: 8px;
    margin-top: 20px;
    font-family: var(--font-family);
    transition: margin-left var(--transition-speed) ease;
  }
  
  /* Responsive adjustment: remove left margin on smaller screens */
  @media (max-width: 768px) {
    .main-content {
      margin-left: 0;
    }
  }
  
  /* Page Header */
  .page-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  
  /* Table Styling */
  .table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
    font-family: var(--font-family);
  }
  
  .th, .td {
    border: 1px solid #dee2e6;
    padding: 12px;
    text-align: center;
  }
  
  .th {
    background-color: var(--info-color);
    color: var(--white);
  }
  
  /* Bold Text */
  .bold {
    font-weight: bold;
  }
  
  /* Buttons */
  .btn {
    padding: 8px 16px;
    margin: 4px;
    cursor: pointer;
    border: none;
    border-radius: 4px;
    font-size: 14px;
  }
  
  .btn-back {
    background-color: var(--secondary-color);
    color: var(--white);
  }
  
  .btn-add {
    background-color: var(--primary-color);
    color: var(--white);
  }
  
  .btn-edit {
    background-color: var(--warning-color);
    color: #212529;
  }
  
  .btn-delete {
    background-color: var(--danger-color);
    color: var(--white);
  }
  
  /* Invoice Button (can be larger) */
  .invoice-btn {
    font-size: 16px;
  }
  
  /* Summary Section */
  .summary {
    margin-top: 20px;
    font-weight: bold;
  }
  
  /* Grand Total Section */
  .grand-total {
    margin-top: 20px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #e9ecef;
    padding: 10px;
    border-radius: 4px;
  }
  
  .total-amount {
    font-size: 18px;
    margin: 0;
  }
  
  /* Modal Overlay & Content */
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modal-content {
    background-color: var(--white);
    padding: 20px;
    border-radius: 8px;
    width: 350px;
    max-width: 90%;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  }
  
  /* Input Fields & Form Groups */
  .input-field {
    width: 100%;
    padding: 10px;
    margin: 8px 0;
    box-sizing: border-box;
    border-radius: 4px;
    border: 1px solid #ced4da;
  }
  
  .form-group {
    margin-bottom: 10px;
  }
  
  /* Modal Button Group */
  .modal-btn-group {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
  }
  
  /* Labour Section */
  .labour-section {
    margin-top: 40px;
  }
  
  /* Loading & Error Messages */
  .loading {
    text-align: center;
    padding: 20px;
  }
  
  .error {
    color: var(--danger-color);
    text-align: center;
    padding: 10px;
  }
  
  /* Success Popup */
  .success-popup {
    position: fixed;
    top: 20px;
    right: 20px;
    background: #4BB543;
    color: var(--white);
    padding: 10px 20px;
    border-radius: 5px;
    z-index: 2000;
  }
  
  /* Responsive Table (for very small screens) */
  @media (max-width: 576px) {
    .table, .table thead, .table tbody, .table th, .table td, .table tr {
      display: block;
    }
    .table tr {
      margin-bottom: 15px;
    }
    .table td {
      text-align: right;
      padding-left: 50%;
      position: relative;
    }
    .table td::before {
      content: attr(data-label);
      position: absolute;
      left: 0;
      width: 50%;
      padding-left: 15px;
      font-weight: bold;
      text-align: left;
    }
    .table th {
      display: none;
    }
  }
  