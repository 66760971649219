/* Users.css */
:root {
    --primary-color: #1ca8cb;
    --primary-hover: #17899e;
    --danger-color: #dc3545;
    --danger-hover: #c82333;
    --success-color: #28a745;
    --success-hover: #218838;
    --light-bg: #f8f9fa;
    --border-color: #ddd;
    --text-color: #333;
    --sidebar-width: 260px;
    --header-height: 60px;
    --transition-speed: 0.3s;
    --shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  /* Main container */
  .users-page {
    min-height: 100vh;
    color: var(--text-color);
  }
  
  .dashboard-container {
    display: flex;
    position: relative;
  }
  
  /* Main content area */
  .main-content {
    flex: 1;
    margin-left: var(--sidebar-width);
    padding: 20px;
    transition: margin-left var(--transition-speed) ease;
  }
  
  .main-content.mobile-view {
    margin-left: 0;
    width: 100%;
  }
  
  /* Page header with title and add button */
  .page-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    flex-wrap: wrap;
    gap: 15px;
  }
  
  /* Search and Filter section */
  .search-filter-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    flex-wrap: wrap;
    gap: 15px;
  }
  
  .search-input {
    padding: 10px;
    font-size: 16px;
    border-radius: 5px;
    border: 1px solid var(--border-color);
    min-width: 200px;
    flex-grow: 1;
  }
  
  .entries-select {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .entries-dropdown {
    padding: 10px;
    font-size: 16px;
    border-radius: 5px;
    border: 1px solid var(--border-color);
  }
  
  /* Table styling */
  .table-responsive {
    overflow-x: auto;
    margin-bottom: 20px;
    box-shadow: var(--shadow);
    border-radius: 8px;
    background-color: white;
  }
  
  .data-table {
    width: 100%;
    border-collapse: collapse;
    min-width: 600px;
  }
  
  .data-table th,
  .data-table td {
    padding: 12px 15px;
    text-align: left;
    border-bottom: 1px solid var(--border-color);
  }
  
  .data-table th {
    background-color: #f2f2f2;
    font-weight: bold;
    position: sticky;
    top: 0;
  }
  
  .data-table tr:hover {
    background-color: rgba(0, 0, 0, 0.03);
  }
  
  /* Avatar styling */
  .avatar-cell {
    width: 60px;
  }
  
  .avatar-img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    object-fit: cover;
  }
  
  /* Status badge */
  .status-badge {
    padding: 5px 10px;
    border-radius: 4px;
    cursor: pointer;
    display: inline-block;
    font-weight: 500;
    text-align: center;
    white-space: nowrap;
    transition: background-color var(--transition-speed) ease;
  }
  
  .status-badge.active {
    background-color: var(--success-color);
    color: white;
  }
  
  .status-badge.inactive {
    background-color: var(--danger-color);
    color: white;
  }
  
  /* Pagination */
  .pagination {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
    flex-wrap: wrap;
    gap: 15px;
  }
  
  .page-info {
    font-weight: 500;
  }
  
  /* Button styles */
  .btn {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color var(--transition-speed) ease, transform 0.1s ease;
    box-shadow: var(--shadow);
  }
  
  .btn:hover {
    transform: translateY(-2px);
  }
  
  .btn:active {
    transform: translateY(0);
  }
  
  .btn-primary {
    background-color: var(--primary-color);
    color: white;
  }
  
  .btn-primary:hover {
    background-color: var(--primary-hover);
  }
  
  .btn-danger {
    background-color: var(--danger-color);
    color: white;
  }
  
  .btn-danger:hover {
    background-color: var(--danger-hover);
  }
  
  .btn-success {
    background-color: var(--success-color);
    color: white;
  }
  
  .btn-success:hover {
    background-color: var(--success-hover);
  }
  
  .btn-edit {
    background-color: var(--primary-color);
    color: white;
    padding: 8px 16px;
  }
  
  .btn-edit:hover {
    background-color: var(--primary-hover);
  }
  
  .btn-pagination {
    background-color: var(--primary-color);
    color: white;
  }
  
  .btn-pagination:disabled {
    background-color: #ccc;
    cursor: not-allowed;
    transform: none;
  }
  
  /* Modal styles */
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
  }
  
  .modal-container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000;
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
    max-width: 90vw;
    width: 500px;
    max-height: 90vh;
    overflow-y: auto;
  }
  
  .modal-title {
    margin-bottom: 20px;
    text-align: center;
    font-weight: bold;
  }
  
  .modal-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
  
  .modal-close-button {
    display: flex;
    justify-content: center;
    margin-top: 15px;
  }
  
  /* Form styles */
  .form-group {
    margin-bottom: 15px;
  }
  
  .form-label {
    display: block;
    margin-bottom: 5px;
    font-weight: 600;
  }
  
  .form-input {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    border: 1px solid var(--border-color);
    border-radius: 5px;
    transition: border-color var(--transition-speed) ease;
    box-sizing: border-box;
  }
  
  .form-input:focus {
    outline: none;
    border-color: var(--primary-color);
  }
  
  /* Different modal sizes */
  .edit-user-modal, .confirm-modal {
    width: 400px;
  }
  
  .register-modal {
    width: 450px;
  }
  
  /* Media Queries for Responsiveness */
  @media (max-width: 992px) {
    .page-header {
      flex-direction: column;
      align-items: flex-start;
    }
    
    .page-header h2 {
      margin-bottom: 10px;
    }
  }
  
  @media (max-width: 768px) {
    /* :root {
      --sidebar-width: 0px;
    } */
    
    .search-filter-container {
        flex-direction: column;
        align-items: stretch;
      }
      
      .entries-select {
        justify-content: space-between;
      }
    
      .pagination {
        justify-content: center;
        flex-direction: column;
        align-items: center;
      }
      
      .modal-container {
        width: 90%;
      }
  }
  
  @media (max-width: 576px) {
    .btn {
      padding: 8px 16px;
      font-size: 14px;
    }
    
    .modal-buttons {
      flex-direction: column;
      gap: 10px;
    }
    
    .table-responsive {
      margin-left: -15px;
      margin-right: -15px;
      width: calc(100% + 30px);
    }
  }