/* Global Variables & Base Styles */
:root {
    --sidebar-width: 300px;
    --primary-color: #4CAF50;
    --font-family: Arial, sans-serif;
    --background-color: #f8f9fa;
    --table-header-bg: #4CAF50;
    --table-header-color: #fff;
    --border-color: #ddd;
  }
  
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }
  
  body {
    font-family: var(--font-family);
    background-color: var(--background-color);
  }
  
  /* Dashboard Layout */
  .dashboard {
    display: flex;
    flex-direction: row;
  }
  
  /* Main Content */
  .content {
    margin-left: var(--sidebar-width);
    padding: 20px;
    flex: 1;
  }
  
  /* Responsive: On screens smaller than 768px, hide the sidebar and remove left margin */
  @media (max-width: 768px) {
    .content {
      margin-left: 0;
    }
  }
  
  /* Search Container */
  .search-container {
    margin-bottom: 20px;
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
  }
  .search-input {
    padding: 10px;
    width: 300px;
    border-radius: 4px;
    border: 1px solid #ccc;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  /* Inventory Table Styles */
  .inventory-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
  }
  .table-header th {
    background-color: var(--table-header-bg);
    color: var(--table-header-color);
    padding: 10px;
    text-align: left;
    border: 1px solid var(--border-color);
  }
  .table-cell {
    padding: 10px;
    border: 1px solid var(--border-color);
    text-align: left;
  }
  .table-row {
    text-align: left;
  }
  
  /* Pagination Styles */
  .pagination {
    margin-top: 20px;
  }
  .page-btn {
    padding: 10px;
    margin: 5px;
    border-radius: 4px;
    border: 1px solid #ccc;
    background-color: #fff;
    cursor: pointer;
  }
  .page-btn.active {
    background-color: var(--table-header-bg);
    color: var(--table-header-color);
  }
  
  /* Loading and Error Text */
  .loading-text {
    text-align: center;
    padding: 20px;
  }
  .error-text {
    text-align: center;
    padding: 10px;
    color: var(--table-header-bg);
  }
  
  /* Responsive Table for very small screens */
  @media (max-width: 576px) {
    .inventory-table,
    .inventory-table thead,
    .inventory-table tbody,
    .inventory-table th,
    .inventory-table td,
    .inventory-table tr {
      display: block;
    }
    .inventory-table tr {
      margin-bottom: 15px;
    }
    .inventory-table td {
      text-align: left;
      padding-left: 50%;
      position: relative;
    }
    .inventory-table td::before {
      content: attr(data-label);
      position: absolute;
      left: 0;
      width: 45%;
      padding-left: 15px;
      font-weight: bold;
      text-align: left;
    }
    .inventory-table th {
      display: none;
    }
  }
  