/* Global Variables */
:root {
    --sidebar-width: 20%;
    --navbar-height: 60px;
    --primary-color: #4caf50;
    --secondary-color: #f1f1f1;
    --error-bg: #fee2e2;
    --error-color: #dc2626;
    --success-bg: #dcfce7;
    --success-color: #15803d;
    --white: #fff;
    --border-color: #ddd;
    --font-family: Arial, sans-serif;
  }
  
  /* Basic Reset */
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }
  body {
    font-family: var(--font-family);
    background-color: #f8f9fa;
  }
  
  /* Dashboard Layout */
  .dashboard {
    display: flex;
    flex-direction: row;
    min-height: 100vh;
  }
  
  /* Sidebar */
  .sidebar {
    width: var(--sidebar-width);
    position: fixed;
    top: var(--navbar-height);
    left: 0;
    height: calc(100vh - var(--navbar-height));
    border-right: 1px solid var(--border-color);
  }
  
  /* Main Content */
  .content {
    margin-top: calc(var(--navbar-height) + 20px);
    margin-left: var(--sidebar-width);
    padding: 20px;
    flex: 1;
  }
  
  /* Responsive: Hide sidebar on small screens */
  @media (max-width: 768px) {
    .sidebar {
      display: none;
    }
    .content {
      margin-left: 0;
    }
  }
  
  /* Search Container */
  .search-container {
    margin-bottom: 20px;
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
  }
  .input-field {
    padding: 8px;
    border: 1px solid var(--border-color);
    border-radius: 4px;
    width: 200px;
  }
  .select-field {
    padding: 8px;
    border: 1px solid var(--border-color);
    border-radius: 4px;
    width: 150px;
  }
  
  /* Header Row */
  .header-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 20px;
  }
  .title {
    font-size: 24px;
    font-weight: bold;
  }
  
  /* Item Information */
  .item-info p {
    margin-bottom: 8px;
  }
  
  /* Subcategory Section */
  .subcategory-section {
    margin-bottom: 32px;
  }
  .subcategory-title {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 16px;
  }
  
  /* Details Table */
  .details-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px;
  }
  .details-table th,
  .details-table td {
    padding: 12px;
    border: 1px solid var(--border-color);
    text-align: center;
  }
  .details-table th {
    background-color: var(--primary-color);
    color: var(--white);
  }
  
  /* Modal Styles */
  .modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.5);
    z-index: 1000;
  }
  .modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: var(--white);
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0,0,0,0.1);
    z-index: 1001;
    width: 400px;
    max-width: 90%;
  }
  .modal-title {
    margin-bottom: 16px;
    font-size: 20px;
    font-weight: bold;
  }
  .modal-subtitle {
    margin-bottom: 16px;
    color: #666;
  }
  .modal-btn-group {
    display: flex;
    justify-content: flex-end;
    gap: 8px;
    margin-top: 20px;
  }
  
  /* Button Styles */
  .btn {
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
    margin-left: 10px;
    font-size: 14px;
  }
  .btn-primary {
    background-color: var(--primary-color);
    color: var(--white);
    border: none;
  }
  .btn-secondary {
    background-color: var(--secondary-color);
    border: 1px solid var(--border-color);
  }
  
  /* Transfer Status Message */
  .transfer-status {
    padding: 12px;
    margin-bottom: 16px;
    border-radius: 4px;
  }
  .transfer-status.error {
    background-color: var(--error-bg);
    color: var(--error-color);
  }
  .transfer-status.success {
    background-color: var(--success-bg);
    color: var(--success-color);
  }
  
  /* Responsive Table: Convert table to block layout on very small screens */
  @media (max-width: 576px) {
    .details-table,
    .details-table thead,
    .details-table tbody,
    .details-table th,
    .details-table td,
    .details-table tr {
      display: block;
    }
    .details-table tr {
      margin-bottom: 15px;
    }
    .details-table td {
      text-align: left;
      padding-left: 50%;
      position: relative;
    }
    .details-table td::before {
      content: attr(data-label);
      position: absolute;
      left: 0;
      width: 50%;
      padding-left: 15px;
      font-weight: bold;
      text-align: left;
    }
    .details-table th {
      display: none;
    }
  }
  