/* Sidebar.css */
:root {
    --sidebar-width: 260px;
    --sidebar-bg: #1a1e24;
    --sidebar-hover: #2a2f38;
    --sidebar-active: #3b4b61;
    --sidebar-text: #fff;
    --logout-bg: #d9534f;
    --logout-hover: #c9302c;
    --transition-speed: 0.3s;
    --box-shadow: 2px 0 5px rgba(0, 0, 0, 0.2);
  }
  
  /* Main sidebar container */
  .sidebar {
    height: 100vh;
    width: var(--sidebar-width);
    background-color: var(--sidebar-bg);
    color: var(--sidebar-text);
    padding: 20px 15px;
    position: fixed;
    left: 0;
    top: 0;
    overflow-y: auto;
    box-shadow: var(--box-shadow);
    display: flex;
    flex-direction: column;
    transition: transform var(--transition-speed) ease, box-shadow var(--transition-speed) ease;
    z-index: 1000;
  }
  
  /* Logo container */
  .logo-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
  }
  
  .logo {
    max-height: 100px;
    max-width: 100%;
    object-fit: contain;
  }
  
  /* Menu list styling */
  .menu-list {
    padding: 0;
    margin: 0;
    list-style: none;
    flex-grow: 1;
  }
  
  .menu-item {
    list-style: none;
    padding: 12px 15px;
    border-radius: 8px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: background-color var(--transition-speed) ease;
  }
  
  .menu-item:hover {
    background-color: var(--sidebar-hover);
  }
  
  .menu-item.active {
    background-color: var(--sidebar-active);
  }
  
  .sidebar-icon {
    margin-right: 12px;
    font-size: 18px;
    min-width: 18px;
  }
  
  .menu-link {
    color: var(--sidebar-text);
    text-decoration: none;
    font-size: 16px;
    flex: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  /* Dropdown indicator styling */
  .dropdown-indicator {
    font-size: 10px;
    transition: transform var(--transition-speed) ease;
  }
  
  .dropdown-indicator.open {
    transform: rotate(180deg);
  }
  
  /* Submenu styling */
  .submenu-list {
    list-style: none;
    padding-left: 20px;
    margin-top: 5px;
    margin-bottom: 10px;
  }
  
  .submenu-item {
    list-style: none;
    padding: 12px 15px;
    padding-left: 30px;
    border-radius: 8px;
    margin-bottom: 5px;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: background-color var(--transition-speed) ease;
  }
  
  .submenu-item:hover {
    background-color: var(--sidebar-hover);
  }
  
  .submenu-item.active {
    background-color: var(--sidebar-active);
  }
  
  /* Logout button styling */
  .logout-button {
    margin-top: 20px;
    padding: 12px;
    background-color: var(--logout-bg);
    border: none;
    color: var(--sidebar-text);
    border-radius: 8px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    width: 100%;
    transition: background-color var(--transition-speed) ease;
  }
  
  .logout-button:hover {
    background-color: var(--logout-hover);
  }
  
  /* Mobile hamburger toggle button */
  .sidebar-toggle {
    display: none;
    position: fixed;
    top: 15px;
    left: 15px;
    z-index: 1001;
    background-color: var(--sidebar-bg);
    color: var(--sidebar-text);
    border: none;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    box-shadow: var(--box-shadow);
  }
  
  /* Overlay for mobile */
  .sidebar-overlay {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
  }
  
  /* Scrollbar styling */
  .sidebar::-webkit-scrollbar {
    width: 5px;
  }
  
  .sidebar::-webkit-scrollbar-track {
    background: var(--sidebar-bg);
  }
  
  .sidebar::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 10px;
  }
  
  /* Media Queries for Responsiveness */
  @media (max-width: 992px) {
    :root {
      --sidebar-width: 240px;
    }
    
    .sidebar {
      padding: 15px 10px;
    }
    
    .logo {
      max-height: 80px;
    }
  }
  
  @media (max-width: 768px) {
    :root {
      --sidebar-width: 260px;
    }
    
    .sidebar {
      transform: translateX(-100%);
      box-shadow: none;
    }
    
    .sidebar.mobile-open {
      transform: translateX(0);
      box-shadow: var(--box-shadow);
    }
    
    .sidebar-toggle {
      display: flex;
    }
    
    .sidebar-overlay {
      display: block;
    }
    
    .menu-item, .submenu-item {
      padding: 15px;
    }
  }
  
  /* For very small screens */
  @media (max-width: 480px) {
    :root {
      --sidebar-width: 85%;
    }
  
    .logo {
      max-height: 70px;
    }
  }
  
  /* Main content adjustment to work with the sidebar */
  .main-content {
    margin-left: var(--sidebar-width);
    padding: 20px;
    transition: margin-left var(--transition-speed) ease;
  }
  
  @media (max-width: 768px) {
    .main-content {
      margin-left: 0;
    }
  }