/* Global Variables & Base Styles */
:root {
    --sidebar-width: 300px;
    --primary-color: #28a745;
    --secondary-color: #6c757d;
    --border-color: #ddd;
    --background-color: #f8f9fa;
    --white: #fff;
    --font-family: Arial, sans-serif;
  }
  
  body {
    font-family: var(--font-family);
    background-color: var(--background-color);
    margin: 0;
  }
  
  /* Dashboard Layout */
  .dashboard-container {
    display: flex;
    flex-direction: row;
  }
  
  /* Main Content Area */
  .main-content {
    margin-left: var(--sidebar-width);
    padding: 20px;
    flex: 1;
  }
  
  /* Responsive: On screens smaller than 768px remove sidebar margin */
  @media (max-width: 768px) {
    .main-content {
      margin-left: 0;
    }
  }
  
  /* Search Container */
  .search-container {
    margin-bottom: 20px;
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
  }
  
  /* Input & Select Fields */
  .input-field {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 200px;
    box-sizing: border-box;
  }
  
  .select-field {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 150px;
    box-sizing: border-box;
  }
  
  /* Table Styling */
  .inventory-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
  }
  
  .table-header {
    background-color: #f2f2f2;
  }
  
  .table-cell {
    padding: 10px;
    border: 1px solid var(--border-color);
    text-align: center;
  }
  
  /* Row styling */
  .table-row {
    text-align: center;
  }
  
  /* Transfer Button */
  .transfer-btn {
    padding: 6px 12px;
    border: none;
    border-radius: 4px;
    background-color: var(--primary-color);
    color: var(--white);
    cursor: pointer;
  }
  
  /* Loading Text */
  .loading-text {
    text-align: center;
    padding: 20px;
  }
  
  /* Modal Styling */
  .modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  
  .modal-content {
    width: 80%;
    max-width: 500px;
    background-color: var(--white);
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  }
  
  /* Form Groups inside Modal */
  .form-group {
    margin-bottom: 15px;
  }
  
  /* Units Container */
  .units-container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }
  
  .unit-option {
    display: flex;
    align-items: center;
    gap: 4px;
  }
  
  /* Modal Button Group */
  .modal-btn-group {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    margin-top: 15px;
  }
  
  /* Button Styles */
  .btn {
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
  }
  
  .btn-primary {
    background-color: var(--primary-color);
    color: var(--white);
  }
  
  .btn-secondary {
    background-color: var(--secondary-color);
    color: var(--white);
  }
  
  /* Responsive Table for Small Devices */
  @media (max-width: 576px) {
    .inventory-table,
    .inventory-table thead,
    .inventory-table tbody,
    .inventory-table th,
    .inventory-table td,
    .inventory-table tr {
      display: block;
    }
    .inventory-table tr {
      margin-bottom: 15px;
    }
        .inventory-table td {
          text-align: left;
          padding-left: 5%;
          position: relative;
        }
        .inventory-table td::before {
          content: attr(data-label);
          position: absolute;
          left: 0;
          width: 50%;
          padding-left: 15px;
          font-weight: bold;
          text-align: left;
        }
        .inventory-table th {
          display: none;
        }
      
      
  }
  