/* Global CSS Variables */
:root {
    --sidebar-width: 260px;
    --transition-speed: 0.3s;
    --primary-color: #1ca8cb;
    --background-color: #f8f9fa;
    --text-color: #333;
  }
  
  /* Dashboard Layout */
  .dashboard-container {
    display: flex;
    flex-direction: row;
  }
  
  /* Main Content Area */
  .main-content {
    margin-left: var(--sidebar-width);
    padding: 20px;
    flex: 1;
    transition: margin-left var(--transition-speed) ease;
    background-color: var(--background-color);
    color: var(--text-color);
  }
  
  /* On smaller screens, remove left margin so content takes full width */
  @media (max-width: 768px) {
    .main-content {
      margin-left: 0;
    }
  }
  
  /* Input Field Styling */
  .input-field {
    width: 100%;
    padding: 8px;
    margin: 8px 0;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-sizing: border-box;
  }
  
  /* Button Styles */
  .btn {
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-right: 10px;
    margin-top: 8px;
  }
  
  .btn-primary {
    background-color: var(--primary-color);
    color: #fff;
  }
  
  .btn-secondary {
    background-color: #ccc;
    color: #333;
  }
  
  /* Responsive Table Styling */
  .responsive-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
  }
  
  .responsive-table th,
  .responsive-table td {
    padding: 8px;
    border: 1px solid #ddd;
    text-align: left;
  }
  
  .responsive-table th {
    background-color: #f2f2f2;
  }
  
  /* Mobile Responsive Table */
  @media (max-width: 576px) {
    .responsive-table,
    .responsive-table thead,
    .responsive-table tbody,
    .responsive-table th,
    .responsive-table td,
    .responsive-table tr {
      display: block;
    }
  
    .responsive-table tr {
      margin-bottom: 15px;
    }
  
    .responsive-table td {
      text-align: right;
      padding-left: 50%;
      position: relative;
    }
  
    .responsive-table td::before {
      content: attr(data-label);
      position: absolute;
      left: 0;
      width: 50%;
      padding-left: 15px;
      font-weight: bold;
      text-align: left;
    }
  
    .responsive-table th {
      display: none;
    }
  }
  
  /* Modal Overlay */
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  
  /* Modal Content */
  .modal-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    width: 500px;
    max-width: 90%;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  /* Error Text */
  .error-text {
    color: red;
    font-size: 12px;
  }
  
  /* Modal Button Group */
  .modal-btn-group {
    display: flex;
    gap: 10px;
  }
  
  /* Success Popup */
  .success-popup {
    position: fixed;
    top: 20px;
    right: 20px;
    background: #4BB543;
    color: #fff;
    padding: 10px 20px;
    border-radius: 5px;
    z-index: 2000;
  }
  